@layer base {
    @font-face {
        font-family: Roobert;
        font-weight: 400;
        src: url('./fonts/Roobert/RoobertRegular.ttf') format('truetype');
    }

    @font-face {
        font-family: Roobert;
        font-weight: 500;
        src: url('./fonts/Roobert/RoobertMedium.ttf') format('truetype');
    }

    @font-face {
        font-family: Roobert;
        font-weight: 600;
        src: url('./fonts/Roobert/RoobertSemiBold.ttf') format('truetype');
    }

    @font-face {
        font-family: Roobert;
        font-weight: 700;
        src: url('./fonts/Roobert/RoobertBold.ttf') format('truetype');
    }

    @font-face {
        font-family: Roobert;
        font-weight: 800;
        src: url('./fonts/Roobert/RoobertHeavy.ttf') format('truetype');
    }
}



@tailwind base;
@tailwind components;
@tailwind utilities;


/* add the code bellow */
@layer utilities {

    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }
}